var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "equipment bgFFF",
    },
    [
      _c("div", { staticClass: "e_content" }, [
        _c("div", { staticClass: "ruleMessage" }, [
          _c("div", { staticClass: "bind" }, [
            _c("div", { staticClass: "subTitle" }, [_vm._v("绑定详情")]),
            _c("div", { staticClass: "detail" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("规则名称")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("规则描述")]),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        maxlength: "600",
                        autosize: { minRows: 10 },
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.formData.descMsg,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "descMsg", $$v)
                        },
                        expression: "formData.descMsg",
                      },
                    }),
                    _c("div", { staticClass: "tips-box" }, [
                      _vm._v(
                        "此内容在车主端显示，是计费规则的文字版描述供车主参考"
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "group" }, [
                _c("div", { staticClass: "label" }, [
                  _c("span", [_vm._v("绑定道路组")]),
                  _c("img", {
                    staticClass: "imgs",
                    attrs: {
                      src: require("../../../assets/img/add.png"),
                      alt: "",
                    },
                    on: { click: _vm.onAdd },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "content" },
                  _vm._l(_vm.roadGroup, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.roadGroupId,
                        class: [
                          "tabRow",
                          item.continueType === 1 ? "tabRow34" : "",
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "rowItem",
                            on: {
                              click: function ($event) {
                                return _vm.onOpenUpdateDia(item.roadGroupId)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.roadGroupName) + " ")]
                        ),
                        _c("div", { staticClass: "rowItem" }, [
                          _vm._v(_vm._s(_vm._f("descFilter")(item))),
                        ]),
                        _c("div", { staticClass: "rowItem" }, [
                          _c("img", {
                            staticClass: "imgs",
                            attrs: {
                              src: require("../../../assets/img/reduce.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDel(item.roadGroupId)
                              },
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "rules" }, [
            _c("div", { staticClass: "subTitle" }, [_vm._v("规则详情")]),
            _c(
              "div",
              { staticClass: "detail" },
              _vm._l(_vm.tableData, function (item) {
                return _c("div", { key: item.id, staticClass: "list" }, [
                  _c("div", { staticClass: "date" }, [
                    _c("span", { staticClass: "version" }, [
                      _vm._v("V" + _vm._s(item.version)),
                    ]),
                    _vm._v(" 生效日期 "),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.effectiveDate)),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "box",
                    domProps: { innerHTML: _vm._s(item.descMsg) },
                  }),
                ])
              }),
              0
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "submit" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
              [_vm._v(_vm._s(_vm.$t("button.preservation")))]
            ),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
      ]),
      _c("BindRoadGroup", {
        ref: "bindRoadGroup",
        attrs: { hasSelectRoadGroup: _vm.roadGroup },
        on: { onAddRoadGroup: _vm.onAddRoadGroup },
      }),
      _c("UpdateRoadGroup", {
        ref: "updateRoadGroup",
        on: { onUpdateRoadGroup: _vm.onUpdateRoadGroup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }